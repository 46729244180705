import { CommonMainPage } from '../utility/CommonMainPage'
import { SerpPresenceFilters } from './component/filters/SerpPresenceFilters'
import { SerpPresenceTable } from './component/report/SerpPresenceTable'

export const SerpPresence = () => {
  return (
    <CommonMainPage>
      <section className='h-full grid gap-[50px] grid-rows-[min-content_1fr]'>
        <SerpPresenceFilters />
        <SerpPresenceTable />
      </section>
    </CommonMainPage>
  )
}
