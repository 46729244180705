import { ReactNode } from 'react'
import { Square } from '../../utility/square/Square'
import { IconButton } from '../../buttons/icon-button/IconButton'
import { IconCrossBold } from '../../icons/IconCrossBold'
import { useOutsideClick } from '../../../hooks/UseOutsideClick'

export interface IBpModalProps {
  children: ReactNode
  className?: string
  size?: 'md' | 'lg' | 'xl'
  onClose?: () => void
  scroll?: boolean
}

export const BpModal = ({
  children,
  className,
  size = 'md',
  scroll = false,
  onClose,
}: IBpModalProps) => {
  const clickRef = useOutsideClick(() => {
    onClose && onClose()
  })

  return (
    <div
      className={`fixed indent-0 flex justify-center py-[8px] items-center bg-focus-500/50 w-full h-full z-30 ${
        scroll ? 'overflow-y-auto !items-start' : 'overflow-hidden'
      }`}
    >
      <div
        className={`flex w-full
          ${{ md: 'max-w-[400px]', lg: 'max-w-[700px]', xl: 'max-w-[830px]' }[size]}
          ${scroll ? '' : 'max-h-full'}
        `}
        ref={clickRef}
      >
        <Square
          className={`flex flex-col relative w-full min-h-[200px]
          ${className}
        `}
        >
          <IconButton className='absolute top-[16px] right-[16px]' onClick={onClose}>
            <IconCrossBold color='#6684A7' />
          </IconButton>
          {children}
        </Square>
      </div>
    </div>
  )
}
