import { setModal } from '../../../../store/reducers/AppSlice'
import { ChangePlan } from '../../../../pages/main/projects/components/ChangePlan'
import { IconCrown } from '../../icons/IconCrown'
import { BpTooltip } from '../../cards/tooltip/BpTooltip'
import { BpButton } from '../bp-button/BpButton'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { selectTariffDetails } from '../../../../store/reducers/AuthSlice'
import { useTranslation } from 'react-i18next'

export const TariffButton = () => {
  const { t } = useTranslation()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const dispatch = useAppDispatch()

  return (
    <BpButton
      size={'sm'}
      theme={'light'}
      className={`ml-auto ${tariffDetails.tariff?.is_subscription_expired && '!bg-[#FF4980]'}`}
      onClick={() =>
        dispatch(
          setModal({
            children: (
              <ChangePlan
                step={tariffDetails.tariff?.is_subscription_expired ? 'change-plan' : 'show-info'}
              />
            ),
          }),
        )
      }
      iconLeft={
        <IconCrown color={tariffDetails.tariff?.is_subscription_expired ? '#FFFFFF' : '#A059FF'} />
      }
      iconRight={
        !tariffDetails.tariff?.is_subscription_expired && (
          <BpTooltip color={'#A059FF'} title={t('pages.tariff.keywordsTooltip')} />
        )
      }
    >
      {tariffDetails.tariff?.is_subscription_expired ? (
        <span className={'text-white'}>
          {t('pages.tariff.tariffExpired', { tariff: tariffDetails.tariff?.name })}
        </span>
      ) : (
        <div className={'font-medium'}>
          {tariffDetails.tariff?.type === 'trial'
            ? `${tariffDetails.tariff?.duration}-day ${tariffDetails.tariff?.name}`
            : tariffDetails.tariff?.name}{' '}
          <span className={'font-bold'}>
            {tariffDetails.tariff?.used_keywords_count}/{tariffDetails.tariff?.max_keywords}
          </span>
        </div>
      )}
    </BpButton>
  )
}
