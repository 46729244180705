import { ResearchToolsFilters } from './filters/ResearchToolsFilters'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { Competitors } from './competitors/Competitors'
import { Keywords } from './keywords/Keywords'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectResearchActiveTab,
  setResearchActiveTab,
  TResearchTabs,
} from '../store/ResearchSlice'
import { useTranslation } from 'react-i18next'

export const ResearchToolsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectResearchActiveTab)

  return (
    <>
      <ResearchToolsFilters />
      <BpTabs
        className='mt-[19px] mb-[16px]'
        active={activeTab}
        onToggle={(e) => dispatch(setResearchActiveTab(e as TResearchTabs))}
      >
        <BpTab title={t('pages.advertisingResearch.advertisers')}>
          <Competitors />
        </BpTab>
        <BpTab title={t('pages.advertisingResearch.keywords')}>
          <Keywords />
        </BpTab>
      </BpTabs>
    </>
  )
}
