import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal, showAlert } from '../../../../store/reducers/AppSlice'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { useGetProjectsQuery } from '../../../../api/filter/Filter.api'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { useAddUserMutation } from '../../../../api/team/Team.api'
import { useTranslation } from 'react-i18next'

export const InviteModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [addUser] = useAddUserMutation()

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery({ all: true })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {},
  })

  const submit = (values: any) => {
    addUser(values).then((response: any) => {
      if (response.data?.success) {
        dispatch(hideModal())
        dispatch(
          showAlert({
            message: `Invitation sent successfully to ${values.email}`,
            type: 'success',
            timeout: 2000,
          }),
        )
      }
    })
  }

  return (
    <form className={'flex flex-col'} onSubmit={handleSubmit(submit)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        {t('pages.team.inviteTeamMember')}
      </h3>
      <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={t('fields.assignName')}
              placeholder={t('fields.assignName')}
              required
              error={errors.name?.message as string}
              {...register('name', {
                required: t('fields.validate.nameRequired'),
              })}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              label={t('fields.email')}
              placeholder={t('fields.email')}
              required
              error={errors.email?.message as string}
              {...register('email', {
                required: t('fields.validate.emailRequired'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('fields.validate.enterValidEmail'),
                },
              })}
            />
          )}
        />
        <Controller
          name='projects'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BpSelect
              {...fieldProps}
              height={150}
              options={projects}
              loading={projectsLoading || projectsFetching}
              disabled={!projects?.length}
              multiple
              showSearch
              label={t('fields.projects')}
              placeholder={t('fields.projects')}
              required
              error={errors.projects?.message as string}
              {...register('projects', {
                required: t('fields.validate.atLeastOneProject'),
              })}
            />
          )}
        />
        <div className={'flex items-center gap-[9px] px-[2px]'}>
          <Controller
            name={'autoAssign'}
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <BpCheckbox
                {...fieldProps}
                checked={value}
                defaultChecked={false}
                label={t('fields.automaticallyAssign')}
              />
            )}
          />
          <BpTooltip
            title={
              'The invited user will be automatically assigned to every new project created in the workspace. This saves time and ensures they have immediate access to new projects without additional actions from your side.'
            }
          />
        </div>
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' disabled={!isValid} className={'uppercase'}>
          {t('buttons.sendInvitation')}
        </BpButton>
        <BpButton type='button' theme='transparent' onClick={() => dispatch(hideModal())}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </form>
  )
}
