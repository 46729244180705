import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { setModal } from '../../../../store/reducers/AppSlice'
import { ProjectAddCountry } from './ProjectAddCountry'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetLocationsQuery,
  useUpdateLocationsMutation,
} from '../../../../api/project/Project.api'
import { Square } from '../../../../shared/components/utility/square/Square'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import {
  useGetBrowserLanguageQuery,
  useGetCitiesQuery,
  useGetEnginesQuery,
} from '../../../../api/filter/Filter.api'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import {
  removeProjectLocation,
  selectProjectLocations,
  selectProjectLocationsChanged,
  setProjectLocations,
  updateProjectLocation,
} from '../store/ProjectsSlice'
import { arraysEqual } from '../../../../shared/helpers/ArrayEqual'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconTrash } from '../../../../shared/components/icons/IconTrash'
import { useTranslation } from 'react-i18next'
import { IconPlus } from '../../../../shared/components/icons/IconPlus'
import { ILocation } from '../interfaces/Location.interface'

const Location = ({ location, engines }: { location: ILocation; engines?: IOption[] }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    data: cities,
    isFetching: citiesFetching,
    isLoading: citiesLoading,
  } = useGetCitiesQuery(
    {
      all: true,
      country_id: location.country_id,
    },
    { skip: !location.country_id },
  )

  const {
    data: browserLanguages,
    isFetching: browserLanguagesFetching,
    isLoading: browserLanguagesLoading,
  } = useGetBrowserLanguageQuery(
    {
      country_id: location.country_id,
    },
    { skip: !location.country_id },
  )

  return (
    <Square className={'py-[24px] px-[22px] flex flex-wrap w-full'}>
      <div className={'flex w-full justify-between'}>
        <h2>{location.country_name}</h2>
        <IconButton
          size={'lg'}
          onClick={() => dispatch(removeProjectLocation(location.country_id))}
        >
          <IconTrash color='#00326D' />
        </IconButton>
      </div>
      <div className={'w-full mt-[20px] flex gap-[4px]'}>
        <div className={'w-1/4'}>
          <BpSelect
            showSearch
            options={browserLanguages}
            value={location.languages?.map((language) => language.id)}
            multiple
            showAlsoField={false}
            label={t('fields.browserLanguages')}
            loading={browserLanguagesLoading || browserLanguagesFetching}
            placeholder={t('fields.selectBrowserLanguages')}
            onChange={(e) => {
              const value = [].slice.call(e.target.selectedOptions).map((o: IOption) => o.value)

              if (
                !arraysEqual(
                  value,
                  location.languages?.map((language) => String(language.id)),
                )
              ) {
                dispatch(
                  updateProjectLocation({
                    ...location,
                    languages: value.map((id) => ({ id })),
                  }),
                )
              }
            }}
            error={location.languages?.length ? '' : t('fields.validate.required')}
            required
          />
        </div>
        <div className={'w-1/4'}>
          <BpSelect
            showSearch
            options={engines}
            value={location.engines?.map((engine) => String(engine.id))}
            multiple
            label={t('fields.searchEngines')}
            placeholder={t('fields.selectSearchEngines')}
            onChange={(e) => {
              const value = [].slice.call(e.target.selectedOptions).map((o: IOption) => o.value)

              if (
                !arraysEqual(
                  value,
                  location.engines?.map((engine) => String(engine.id)),
                )
              ) {
                dispatch(
                  updateProjectLocation({
                    ...location,
                    engines: value.map((id) => ({ id: Number(id) })),
                  }),
                )
              }
            }}
            error={location.engines?.length ? '' : t('fields.validate.required')}
            required
          />
        </div>
        <div className={'w-1/2'}>
          <BpSelect
            showSearch
            options={cities}
            value={location.cities?.map((city) => String(city.id))}
            multiple
            label={t('fields.cities')}
            loading={citiesLoading || citiesFetching}
            placeholder={t('fields.selectCities')}
            onChange={(e) => {
              const value = e.target.selectedOptions.length
                ? [].slice.call(e.target.selectedOptions).map((o: IOption) => o.value)
                : [].slice.call(cities).map((o: IOption) => o.value)

              if (
                !arraysEqual(
                  value,
                  location.cities?.map((city) => String(city.id)),
                )
              ) {
                dispatch(
                  updateProjectLocation({
                    ...location,
                    cities: value.map((id) => ({ id: Number(id) })),
                  }),
                )
              }
            }}
            error={location.cities?.length ? '' : t('fields.validate.required')}
            required
          />
        </div>
      </div>
    </Square>
  )
}

export const ProjectLocations = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const locationsList = useAppSelector(selectProjectLocations)
  const locationsChanged = useAppSelector(selectProjectLocationsChanged)
  const dispatch = useAppDispatch()

  const { data, isSuccess, isFetching } = useGetLocationsQuery(
    { project_id: id || '' },
    { skip: !id },
  )
  const [updateLocations] = useUpdateLocationsMutation()

  const { data: engines, isLoading: enginesLoading } = useGetEnginesQuery()

  useEffect(() => {
    if (isSuccess && data?.data.items) {
      dispatch(setProjectLocations(data?.data.items))
    }
  }, [isFetching])

  const submit = () => {
    updateLocations({ id, data: locationsList }).then((response) => {
      if ('error' in response || !response.data.success) {
        return
      } else {
        dispatch(setProjectLocations(response.data.data.items))
      }
    })
  }

  const cancel = () => {
    dispatch(setProjectLocations(data?.data.items))
  }

  if (enginesLoading || isFetching) {
    return <Preloader />
  }

  return (
    <div className={'grid grid-rows-[auto_1fr_auto] h-full items-start pt-[24px]'}>
      <div className={'flex min-h-[40px] items-center justify-between'}>
        <p className={'max-w-[600px] text-focus-500 text-[13px]'}>
          {t('pages.project.locationsDescription')}
        </p>
        <BpButton
          className={'uppercase'}
          theme={'outline'}
          iconLeft={<IconPlus />}
          onClick={() => dispatch(setModal({ children: <ProjectAddCountry id={id} /> }))}
        >
          {t('buttons.addLocation')}
        </BpButton>
      </div>
      <div className={'mt-[32px] flex flex-wrap gap-[16px]'}>
        {locationsList?.map((location: ILocation, index: number) => (
          <Location key={index} location={location} engines={engines} />
        ))}
      </div>
      <div
        className={
          'flex gap-[12px] px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px] border-t-[3px] border-focus-600/[.03] pt-[24px] mt-[24px]'
        }
      >
        <BpButton
          disabled={!locationsChanged || !locationsList?.length}
          onClick={submit}
          className={'uppercase'}
        >
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton disabled={!locationsChanged} onClick={cancel} theme='transparent'>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </div>
  )
}
