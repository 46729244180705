import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { IEditAffiliateFormData } from '../../ad-overview/components/affiliates-report/edit-affiliate-modal/EditAffiliateForm'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { useGetAffiliateQuery, useUpdateMutation } from '../../../../api/affiliate/Affiliate.api'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { useTranslation } from 'react-i18next'

export interface IPpcComplianceEditAffiliateProps {
  id: number
  projectId: string
}

export const PpcComplianceEditAffiliate = ({ id, projectId }: IPpcComplianceEditAffiliateProps) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useForm<IEditAffiliateFormData>({
    mode: 'onChange',
    defaultValues: {},
  })
  const dispatch = useAppDispatch()

  const { data, isLoading, isFetching, isError } = useGetAffiliateQuery(
    {
      id,
      project_id: projectId,
    },
    { selectFromResult: (state) => ({ ...state, data: state.data?.data?.items[0] }) },
  )
  const [update] = useUpdateMutation()

  useEffect(() => {
    isError && dispatch(hideModal())
  }, [isError])

  if (isLoading || isFetching) {
    return (
      <div className='relative min-h-[200px]'>
        <Preloader />
      </div>
    )
  }

  const saveChanges = (): void => {
    const values = getValues()

    update({ id, ...values }).then(() => {
      dispatch(hideModal())
    })
  }

  const AnchorList = ({ array }: { array?: string[] }) => {
    return (
      <>
        {array?.slice(0, 5).map((value, index) => (
          <BpAnchor href={`https://${value}`} key={index} target='_blank'>
            {value}
          </BpAnchor>
        ))}

        {array && array.length > 5 && (
          <span className={'text-[#6684A7]'}>and {array.length - 5} more</span>
        )}
      </>
    )
  }

  return (
    <form className={'flex flex-col overflow-y-hidden'} onSubmit={handleSubmit(saveChanges)}>
      <h3
        className={
          'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        {t('pages.PPCCompliance.affiliate')} {data?.name ?? data?.pub_value}
      </h3>
      <div className={'overflow-y-auto'}>
        <div className={'flex flex-col gap-[16px] px-[24px] pt-[16px]'}>
          <div className={'text-[15px] flex justify-between'}>
            <span className={'text-[#6684A7]'}>{t('pages.PPCCompliance.createdAt')}</span>{' '}
            {data?.created_at}
          </div>
          <div className={'text-[15px] flex justify-between'}>
            <span className={'text-[#6684A7]'}>{t('pages.PPCCompliance.updatedAt')}</span>{' '}
            {data?.updated_at}
          </div>
          <div className={'text-[15px] flex justify-between'}>
            <span className={'text-[#6684A7]'}>{t('pages.PPCCompliance.project')}</span>{' '}
            {data?.project_name}
          </div>
          <div className={'text-[15px] flex justify-between'}>
            <span className={'text-[#6684A7]'}>{t('pages.PPCCompliance.advertiser')}</span>
            <div className={'flex flex-col items-end'}>
              <AnchorList array={data?.advertisers} />
            </div>
          </div>
          <div className={'text-[15px] flex justify-between'}>
            <span className={'text-[#6684A7]'}>{t('pages.PPCCompliance.trackingDomain')}</span>
            <div className={'flex flex-col items-end'}>
              <AnchorList array={data?.domains} />
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
          <Controller
            name='name'
            control={control}
            defaultValue={data?.name}
            render={({ field }) => (
              <BpInput
                {...field}
                label={t('fields.affiliateId')}
                placeholder={t('fields.enterYourName')}
              />
            )}
          />
          <Controller
            name='email'
            control={control}
            defaultValue={data?.email}
            render={({ field }) => (
              <BpInput
                {...field}
                label={t('fields.affiliateEmail')}
                placeholder={t('fields.enterAffiliatesEmail')}
              />
            )}
          />
          <Controller
            name='comment'
            control={control}
            defaultValue={data?.comment}
            render={({ field }) => (
              <BpInput
                {...field}
                label={t('fields.comment')}
                placeholder={t('fields.enterYourComment')}
              />
            )}
          />
        </div>
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[24px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton type='submit' className={'uppercase'} disabled={!isDirty}>
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton type='button' theme='outline' onClick={() => dispatch(hideModal())}>
          {t('buttons.cancel')}
        </BpButton>
      </div>
    </form>
  )
}
