import { CommonMainPage } from '../utility/CommonMainPage'
import { PositionTrackerFilters } from './components/filter/PositionTrackerFilters'
import { PositionTrackerChart } from './components/report/PositionTrackerChart'
import { PositionTrackerTable } from './components/report/PositionTrackerTable'

export const PositionTrackerPage = () => {
  return (
    <CommonMainPage>
      <section className='h-full grid gap-[50px] grid-rows-[min-content_min-content_1fr]'>
        <PositionTrackerFilters />
        <PositionTrackerChart />
        <PositionTrackerTable />
      </section>
    </CommonMainPage>
  )
}
