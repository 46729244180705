import { CommonMainPage } from '../../../utility/CommonMainPage'
import { ProjectsFilters } from '../ProjectsFilters'
import { ProjectsTable } from '../ProjectsTable'

export const ProjectsPage = () => {
  return (
    <CommonMainPage>
      <section className='h-full grid grid-rows-[min-content_1fr]'>
        <ProjectsFilters />
        <ProjectsTable />
      </section>
    </CommonMainPage>
  )
}
