import React, { useEffect, useState } from 'react'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import {
  useGetBrowserLanguageQuery,
  useGetCitiesQuery,
  useGetCountryQuery,
  useGetDeviceTypesQuery,
  useGetEnginesQuery,
} from '../../../../api/filter/Filter.api'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import {
  useCreateProjectMutation,
  useGetLocationsDefaultSettingsQuery,
  useLazyGetLocationsDefaultSettingsQuery,
} from '../../../../api/project/Project.api'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { hideModal, setModal, showAlert } from '../../../../store/reducers/AppSlice'
import { useTranslation } from 'react-i18next'
import { ChangePlan } from './ChangePlan'
import { selectTariffDetails, setTariffUpdated } from '../../../../store/reducers/AuthSlice'
import { CardDrop } from '../../../../shared/components/cards/card-drop/CardDrop'

export const AddProject = () => {
  const { t } = useTranslation()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const [step, setStep] = useState(1)
  const [disabled, setDisabled] = useState(true)
  const dispatch = useAppDispatch()

  const firstStepForm = useForm<{
    project_name: string
    brand_name: string
    brand_variants: string
    domains: string
    engines: boolean[]
    devices: boolean[]
  }>({
    mode: 'onChange',
    defaultValues: {
      project_name: '',
      brand_name: '',
      brand_variants: '',
      domains: '',
      engines: [],
      devices: [],
    },
  })

  const secondStepForm = useForm<{
    country: string
    cities: string[]
    browser_language: string
  }>({
    mode: 'onChange',
    defaultValues: {
      country: '',
      cities: [],
      browser_language: '',
    },
  })

  const thirdStepForm = useForm<{
    terms: string
  }>({
    mode: 'onChange',
    defaultValues: {
      terms: '',
    },
  })

  const [createProject] = useCreateProjectMutation()

  const {
    control: firstStepControl,
    register: firstStepRegister,
    watch: firstStepWatch,
    setValue: firstStepSetValue,
    setError: firstStepSetError,
    resetField: firstStepResetField,
    formState: { errors: firstStepErrors, isValid: firstStepValid },
  } = firstStepForm

  const {
    control: secondStepControl,
    register: secondStepRegister,
    watch: secondStepWatch,
    setValue: secondStepSetValue,
    setError: secondStepSetError,
    formState: { errors: secondStepErrors, isValid: secondStepValid },
  } = secondStepForm

  const {
    control: thirdStepControl,
    register: thirdStepRegister,
    setValue: thirdStepSetValue,
    watch: thirdStepWatch,
  } = thirdStepForm

  const firstStepValues = firstStepWatch()
  const secondStepValues = secondStepWatch()
  const thirdStepValues = thirdStepWatch()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery()

  const [getLocationsDefaultSettings] = useLazyGetLocationsDefaultSettingsQuery()

  const {
    data: locationsDefaultSettings,
    isFetching: locationsDefaultSettingsFetching,
    isLoading: locationsDefaultSettingsLoading,
  } = useGetLocationsDefaultSettingsQuery(
    {
      'country_ids[]': [secondStepValues?.country],
    },
    { skip: !secondStepValues?.country },
  )

  const {
    data: cities,
    isFetching: citiesFetching,
    isLoading: citiesLoading,
  } = useGetCitiesQuery(
    {
      all: true,
      country_id: Number(secondStepValues?.country),
    },
    { skip: !secondStepValues?.country },
  )

  const { data: engines, isLoading: enginesLoading } = useGetEnginesQuery()

  const { data: devices, isLoading: devicesLoading } = useGetDeviceTypesQuery()

  const { data: browserLanguages } = useGetBrowserLanguageQuery(
    {
      country_id: Number(secondStepValues?.country),
    },
    { skip: !secondStepValues?.country },
  )

  useEffect(() => {
    const first =
      !firstStepValid ||
      !!domainsValidate(firstStepValues?.domains).length ||
      !firstStepValues?.engines.filter((engine) => engine).length ||
      !firstStepValues?.devices.filter((device) => device).length
    const second =
      !secondStepValid ||
      !secondStepValues?.cities.length ||
      !secondStepValues?.browser_language.length
    const third =
      !thirdStepValues.terms.length ||
      !/.*\S+.*/.test(thirdStepValues.terms) ||
      (tariffDetails.tariff &&
        tariffDetails.tariff.max_keywords <
          tariffDetails.tariff.used_keywords_count + thirdStepValues.terms.split('\n').length)

    setDisabled(
      {
        1: first,
        2: second,
        3: third,
      }[step] || false,
    )
  }, [firstStepValues, secondStepValues, thirdStepValues])

  useEffect(() => {
    if (locationsDefaultSettings?.data) {
      secondStepSetValue(
        'browser_language',
        locationsDefaultSettings.data?.items[0].languages[0].id,
      )
    } else {
      secondStepSetValue('browser_language', '')
    }
  }, [browserLanguages])

  const domainsValidate = (list: string): string[] => {
    const domainRegex = /^(?!-)(?:[A-Za-zА-Яа-яЁё0-9-]{1,63}\.)+[A-Za-zА-Яа-яЁё]{2,}$/

    return list
      .split('\n')
      .map((line: string) => line.trim())
      .filter((line: string) => line && !domainRegex.test(line))
  }

  const submit = () => {
    const values = { ...firstStepValues, ...secondStepValues, ...thirdStepValues }

    createProject({
      step: { 1: 'first', 2: 'second', 3: 'third' }[step],
      project_name: values.project_name,
      brand_name: values.brand_name,
      brand_variants: values?.brand_variants?.split('\n'),
      domains: values?.domains?.split('\n'),
      engines: engines
        ?.filter((_, index) => values?.engines[index])
        .map((engine) => ({ id: Number(engine.value) })),
      devices: devices
        ?.filter((_, index) => values?.devices[index])
        .map((device) => ({ id: Number(device.value) })),
      locations: [
        {
          country_id: Number(values?.country),
          languages: [{ id: values?.browser_language }],
          cities: values?.cities?.map((city) => ({ id: Number(city) })),
          engines: engines
            ?.filter((_, index) => values?.engines[index])
            .map((engine) => ({ id: Number(engine.value) })),
        },
      ],
      terms: {
        country_id: Number(values?.country),
        terms: values?.terms?.split('\n'),
        language_code: values?.browser_language,
      },
    }).then((response: any) => {
      if (step < 3) {
        setStep(step + 1)
      } else {
        if (response.data.success) {
          dispatch(setTariffUpdated())
          dispatch(hideModal())
          dispatch(
            showAlert({
              message: t('pages.projects.projectCreatedSuccessfully'),
              type: 'success',
              timeout: 2000,
            }),
          )
        }
      }
    })
  }

  if (enginesLoading || devicesLoading) {
    return <Preloader />
  }

  return (
    <div className={`${step === 1 && 'overflow-hidden'} flex flex-col`}>
      <p className={'px-[24px] pt-[17px] text-[13px] text-default font-medium'}>
        {t('pages.projects.stepOfSteps', { step, steps: 3 })}
      </p>
      <form className={step === 1 ? 'flex flex-col overflow-y-hidden' : 'hidden'}>
        <h3
          className={
            'px-[24px] text-[20px] pb-[8px] font-medium border-b-[3px] border-focus-600/[.03]'
          }
        >
          {t('pages.projects.newProject')}
        </h3>
        <div className={'px-[24px] py-[20px] flex flex-col gap-[14px] overflow-y-auto'}>
          <Controller
            name='project_name'
            control={firstStepControl}
            render={({ field }) => (
              <BpInput
                {...field}
                label={t('fields.projectName')}
                placeholder={t('pages.projects.placeholders.projectName')}
                onClear={() => firstStepResetField('project_name', { defaultValue: '' })}
                required
                error={firstStepErrors.project_name?.message}
                {...firstStepRegister('project_name', {
                  onBlur: (e) => firstStepSetValue('project_name', e.target.value.trim()),
                  required: t('fields.validate.required'),
                  maxLength: {
                    value: 100,
                    message: t('pages.projects.validate.projectName'),
                  },
                  pattern: /.*\S+.*/,
                })}
              />
            )}
          />
          <Controller
            name='brand_name'
            control={firstStepControl}
            render={({ field }) => (
              <BpInput
                {...field}
                label={t('fields.brandName')}
                placeholder={t('pages.projects.placeholders.brandName')}
                onClear={() => firstStepResetField('brand_name', { defaultValue: '' })}
                required
                error={firstStepErrors.brand_name?.message}
                {...firstStepRegister('brand_name', {
                  onBlur: (e) => firstStepSetValue('brand_name', e.target.value.trim()),
                  required: t('fields.validate.required'),
                  maxLength: {
                    value: 100,
                    message: t('pages.projects.validate.brandName'),
                  },
                  pattern: /.*\S+.*/,
                })}
              />
            )}
          />
          <Controller
            name='brand_variants'
            control={firstStepControl}
            render={({ field }) => (
              <BpTextArea
                {...field}
                height='lg'
                label={t('fields.brandsMisspellingsVariations')}
                placeholder={t('pages.projects.placeholders.brandsMisspellingsVariations')}
                info={t('pages.projects.info.brandsMisspellingsVariations')}
                required
                error={firstStepErrors.brand_variants?.message}
                {...firstStepRegister('brand_variants', {
                  required: t('fields.validate.required'),
                  pattern: /.*\S+.*/,
                  onChange: (e) => {
                    firstStepSetValue('brand_variants', e.target.value.replace(',', '\n'))
                  },
                  onBlur: (e) => {
                    firstStepSetValue(
                      'brand_variants',
                      e.target.value
                        .split('\n')
                        .map((value: string) => value.trim())
                        .join('\n'),
                    )
                  },
                })}
              />
            )}
          />
          <Controller
            name='domains'
            control={firstStepControl}
            render={({ field }) => (
              <BpTextArea
                {...field}
                height='lg'
                label={t('fields.domains')}
                placeholder={t('pages.projects.placeholders.domains')}
                info={t('pages.projects.info.domains')}
                required
                error={firstStepErrors.domains?.message}
                {...firstStepRegister('domains', {
                  required: t('fields.validate.required'),
                  pattern: /.*\S+.*/,
                  onChange: (e) => {
                    firstStepSetValue(
                      'domains',
                      e.target.value.replace(',', '\n').replace(/[^a-zA-Zа-яА-Я0-9\s.,-:/]/g, ''),
                    )
                  },
                  onBlur: (e) => {
                    firstStepSetValue(
                      'domains',
                      e.target.value
                        .split('\n')
                        .map((value: string) => value.trim())
                        .join('\n'),
                    )

                    if (domainsValidate(e.target.value).length > 0) {
                      firstStepSetError('domains', { message: t('fields.validate.domain') })
                    }
                  },
                })}
              />
            )}
          />
          <div>
            <div className={'text-[14px] font-medium mb-[4px] leading-[24px] text-focus-500'}>
              {t('fields.selectSearchEnginesForScanning')}
              <span className={'text-default'}> *</span>
            </div>
            <div className={'flex gap-[16px]'}>
              {engines?.map((engine, index) => {
                const fieldName = `engines.${index}` as const
                return (
                  <Controller
                    key={index}
                    name={fieldName}
                    control={firstStepControl}
                    render={({ field: { value, ...fieldProps } }) => (
                      <BpCheckbox
                        {...fieldProps}
                        checked={value}
                        defaultChecked={false}
                        label={engine.text}
                      />
                    )}
                  />
                )
              })}
            </div>
          </div>
          <div>
            <div className={'text-[14px] font-medium mb-[4px] leading-[24px] text-focus-500'}>
              {t('fields.selectDevicesForScanning')}
              <span className={'text-default'}> *</span>
            </div>
            <div className={'flex gap-[16px]'}>
              {devices?.map((device, index) => {
                const fieldName = `devices.${index}` as const
                return (
                  <Controller
                    key={index}
                    name={fieldName}
                    control={firstStepControl}
                    render={({ field: { value, ...fieldProps } }) => (
                      <BpCheckbox
                        {...fieldProps}
                        checked={value}
                        defaultChecked={false}
                        label={t('fields.options.' + device.text)}
                      />
                    )}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </form>
      <form className={step === 2 ? 'flex flex-col' : 'hidden'}>
        <h3
          className={
            'px-[24px] text-[20px] pb-[8px] font-medium border-b-[3px] border-focus-600/[.03]'
          }
        >
          {t('pages.projects.location')}
        </h3>
        <div className={'px-[24px] py-[20px] flex flex-col gap-[14px]'}>
          <p className={'px-[16px] py-[10px] rounded-[12px] bg-focus-500/[.03] text-focus-500'}>
            {t('pages.projects.selectOneCountry')}
          </p>
          <Controller
            name='country'
            control={secondStepControl}
            render={({ field }) => (
              <BpSelect
                {...field}
                height={150}
                options={countries}
                showSearch
                disabled={!countries?.length}
                loading={countriesLoading || countriesFetching}
                label={t('fields.country')}
                placeholder={t('fields.selectCountry')}
                required
                error={secondStepErrors.country?.message}
                {...secondStepRegister('country', {
                  required: t('fields.validate.required'),
                  onChange: (e) => {
                    secondStepSetValue('country', e.target.value)
                  },
                })}
              />
            )}
          />
          <Controller
            name='cities'
            control={secondStepControl}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSelect
                {...fieldProps}
                height={150}
                options={cities}
                multiple
                showSearch
                showAlsoField={false}
                disabled={!cities?.length}
                loading={citiesLoading || citiesFetching}
                label={t('fields.cities')}
                placeholder={t('fields.selectCity')}
                error={secondStepErrors.cities?.message}
                onChange={(e) => {
                  const value = [].slice.call(e.target.selectedOptions).map((o: IOption) => o.value)
                  value.length
                    ? onChange(value)
                    : onChange([].slice.call(cities).map((o: IOption) => o.value))

                  !value.length &&
                    secondStepSetError('cities', {
                      message: t('fields.validate.required'),
                      type: 'required',
                    })
                }}
                required
              />
            )}
          />
          <Controller
            name='browser_language'
            control={secondStepControl}
            render={({ field }) => (
              <BpSelect
                {...field}
                height={150}
                showSearch
                options={browserLanguages}
                disabled={!browserLanguages?.length}
                label={t('fields.browserLanguage')}
                placeholder={t('fields.selectLanguage')}
                required
                error={secondStepErrors.browser_language?.message}
                {...secondStepRegister('browser_language', {
                  required: t('fields.validate.required'),
                })}
              />
            )}
          />
        </div>
      </form>
      <form className={step === 3 ? 'flex flex-col' : 'hidden'}>
        <h3
          className={
            'px-[24px] text-[20px] pb-[8px] font-medium border-b-[3px] border-focus-600/[.03]'
          }
        >
          {t('pages.projects.searchTerms')}
        </h3>
        <div className={'px-[24px] py-[20px] flex flex-col gap-[14px]'}>
          <Controller
            name={'terms'}
            control={thirdStepControl}
            render={({ field }) => (
              <BpTextArea
                {...field}
                height='lg'
                label={`${
                  countries?.find((country) => country.value === secondStepValues?.country)?.text
                }, ${
                  browserLanguages?.find(
                    (language) => language.value === secondStepValues?.browser_language,
                  )?.text
                }`}
                placeholder={t('pages.projects.placeholders.terms')}
                required
                {...thirdStepRegister('terms', {
                  required: t('fields.validate.required'),
                  onChange: (e) => {
                    thirdStepSetValue('terms', e.target.value.replace(',', '\n'))
                  },
                  onBlur: (e) => {
                    thirdStepSetValue(
                      'terms',
                      e.target.value
                        .split('\n')
                        .map((value: string) => value.trim())
                        .join('\n'),
                    )
                  },
                })}
              />
            )}
          />
          {tariffDetails.tariff &&
            tariffDetails.tariff.max_keywords <
              tariffDetails.tariff.used_keywords_count +
                thirdStepValues.terms.split('\n').length && (
              <div
                className={
                  'bg-[#FF4980] rounded-[6px] p-[12px] gap-[10px] flex flex-wrap justify-between'
                }
              >
                <p className={'text-white text-[15px] w-full'}>
                  You&apos;ve exceeded the limit of keywords for Standard. Please stay within the
                  limit or update your plan.
                </p>
                <div className={'flex gap-[8px]'}>
                  <BpButton
                    theme={'light'}
                    size={'sm'}
                    type={'button'}
                    onClick={() => dispatch(setModal({ children: <ChangePlan /> }))}
                    contentClassName={'!text-[#00326D] !font-normal'}
                  >
                    Change Plan
                  </BpButton>
                  <CardDrop title={'Details'} position={'left'}>
                    <div className={'min-w-[300px] p-[16px]'}>
                      <div className={'flex items-center gap-[8px]'}>
                        <p className={'text-[15px]'}>
                          Used{' '}
                          <span className={'font-semibold'}>
                            {tariffDetails?.tariff?.used_keywords_count}/
                            {tariffDetails?.tariff?.max_keywords}
                          </span>{' '}
                          keywords
                        </p>
                      </div>
                      <ul
                        className={'max-h-[110px] overflow-y-auto text-[15px] pl-[22px] list-disc'}
                      >
                        {Object.entries(tariffDetails?.location_details).map(([key, value]) => (
                          <li key={value}>
                            {key}: {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </CardDrop>
                </div>
              </div>
            )}
        </div>
      </form>
      <div
        className={
          'flex justify-end pb-[21px] pt-[24px] px-[24px] border-t-[3px] border-focus-600/[.03]'
        }
      >
        {step > 1 && (
          <BpButton
            type={'button'}
            size={'xs'}
            theme={'transparent'}
            contentClassName={'text-aqua-300 font-normal'}
            onClick={() => setStep(step - 1)}
          >
            {t('buttons.back')}
          </BpButton>
        )}
        <BpButton
          className={'ml-auto uppercase'}
          type='button'
          onClick={() => submit()}
          disabled={disabled}
        >
          {step !== 3 ? t('buttons.next') : t('buttons.createProject')}
        </BpButton>
      </div>
    </div>
  )
}
