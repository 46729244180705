import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { ColumnsType } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import {
  selectPpcComplianceFilter,
  selectPpcCompliancePagination,
  setPpcComplianceCounter,
  setPpcCompliancePagination,
} from '../store/PpcComplianceSlice'
import { useGetNonCompliantAffiliatesQuery } from '../../../../api/compliance/Compliance.api'
import { INonCompliantAffiliatesParams } from '../../../../api/compliance/interfaces/NonCompliantAffiliatesParams.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { NonCompliantAffiliatesModel } from '../../../../api/compliance/models/NonCompliantAffiliates.model'
import { Space, Tooltip } from 'antd'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { useGetAffiliateStatusQuery } from '../../../../api/filter/Filter.api'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconEyeOff } from '../../../../shared/components/icons/IconEyeOff'
import { IconDocument } from '../../../../shared/components/icons/IconDocument'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import { IconEdit } from '../../../../shared/components/icons/IconEdit'
import { useHideMutation, useUpdateStatusMutation } from '../../../../api/affiliate/Affiliate.api'
import { IconEye } from '../../../../shared/components/icons/IconEye'
import { setModal } from '../../../../store/reducers/AppSlice'
import { PpcComplianceLastAd } from './PpcComplianceLastAd'
import { PpcComplianceEditAffiliate } from './PpcComplianceEditAffiliate'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { useNavigate } from 'react-router-dom'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { PpcComplianceReportAds } from './PpcComplianceReportAds'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import {
  initialAdOverviewFilters,
  setAdOverviewFilter,
} from '../../ad-overview/store/AdOverviewSlice'
import { objectToQuery } from '../../../../shared/helpers/ObjectToQuery'
import { useTranslation } from 'react-i18next'

export const PpcComplianceTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const filters = useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const { current, pageSize, total } = useAppSelector<IPagination>(selectPpcCompliancePagination)
  const {
    project,
    country,
    affiliate,
    network,
    dateRange,
    site,
    engine,
    browser,
    city,
    hidden,
    adHijackers,
    status,
  } = filters
  const dispatch = useAppDispatch()

  const [queryParams, setQueryParams] = useState<INonCompliantAffiliatesParams>({
    sort: '-last_found',
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching, refetch } = useGetNonCompliantAffiliatesQuery(queryParams, {
    skip: !queryParams.project_id,
    refetchOnMountOrArgChange: true,
  })
  const [updateStatus] = useUpdateStatusMutation()
  const [hide] = useHideMutation()

  const { data: statuses } = useGetAffiliateStatusQuery()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      project_id: project ? Number(project) : undefined,
      country_id: country ? Number(country) : undefined,
      affiliate,
      network: network,
      date: dateRange,
      site: site,
      engine_id: engine ? Number(engine) : undefined,
      browser_id: browser ? Number(browser) : undefined,
      city_id: city ? Number(city) : undefined,
      is_hidden: hidden,
      is_hijack: adHijackers,
      status_id: status ? Number(status) : undefined,
      policy: adHijackers ? 'ad_hijacking' : undefined,
    })
  }, [filters])

  useEffect(() => {
    data && dispatch(setPpcComplianceCounter(data?.data?.tab_count))
  }, [data])

  const columns: ColumnsType<NonCompliantAffiliatesModel> = [
    {
      title: t('table.columns.affiliateId'),
      key: 'id',
      render: (
        _: string,
        { affiliate_name, pub_value, affiliate }: NonCompliantAffiliatesModel,
      ) => {
        return (
          <div className='flex flex-col gap-[4px]'>
            <span className={'text-[15px] max-w-[185px] truncate whitespace-nowrap'}>
              {affiliate_name ?? (pub_value.length ? pub_value : affiliate)}
            </span>
            {(affiliate_name || pub_value) && (
              <Tooltip
                title={affiliate}
                className='text-[10px] leading-none max-w-[130px] truncate'
              >
                {affiliate}
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.ads'),
      dataIndex: 'ads_count',
      key: 'ads_count',
      sorter: true,
      render: (_: string, { ads_count, affiliate }: NonCompliantAffiliatesModel) => {
        const params = objectToQuery({
          ...initialAdOverviewFilters,
          affiliate,
          country,
          dateRange,
          project,
          city,
          browser,
          engine,
          policy: adHijackers ? 'ad_hijacking' : '',
        })

        return (
          <BpAnchor
            href={`/adv-overview?${params}`}
            onClick={(e) => {
              e.preventDefault()
              navigate('/adv-overview')
              dispatch(
                setAdOverviewFilter({
                  ...initialAdOverviewFilters,
                  affiliate,
                  country,
                  dateRange,
                  project,
                  city,
                  browser,
                  engine,
                  policy: adHijackers ? 'ad_hijacking' : '',
                }),
              )
            }}
          >
            {ads_count}
          </BpAnchor>
        )
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.network')}
          <BpTooltip title={t('pages.PPCCompliance.networkTooltip')} />
        </span>
      ),
      key: 'network_name',
      render: (_: string, { network_name, network_value }: NonCompliantAffiliatesModel) => {
        return (
          <div className='flex flex-col gap-[4px] justify-center'>
            <span className={`text-[15px] ${!network_name && 'text-[#6684A7]'}`}>
              {network_name ?? 'Unspecified'}
            </span>
            {network_value && <span className='text-[10px] leading-none'>{network_value}</span>}
          </div>
        )
      },
    },
    {
      title: t('table.columns.status'),
      dataIndex: 'status_id',
      key: 'status_id',
      render: (_: string, { id, status_id }: NonCompliantAffiliatesModel) => {
        return (
          <BpSelect
            className={'max-w-[200px]'}
            options={statuses}
            value={status_id}
            defaultValue={status_id}
            fieldSize='sm'
            height={110}
            hasTranslate
            onChange={(e) => {
              if (Number(e.target.value) !== status_id) {
                updateStatus({ id, status_id: Number(e.target.value) }).then(() => refetch())
              }
            }}
          />
        )
      },
      width: 250,
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      defaultSortOrder: 'descend',
      sorter: true,
    },
    {
      key: 'edit',
      render: (
        _: string,
        {
          id,
          affiliate,
          ads_count,
          project_id,
          network_id,
          network_name,
        }: NonCompliantAffiliatesModel,
      ) => {
        return (
          <div className='flex w-full justify-end  whitespace-nowrap'>
            <BpButton
              className='w-full'
              theme='outline'
              size='sm'
              onClick={() => {
                dispatch(
                  setModal({
                    size: 'lg',
                    children: (
                      <PpcComplianceReportAds
                        id={id}
                        adsCount={ads_count}
                        affiliate={affiliate}
                        projectId={project_id}
                        networkId={network_id}
                        networkName={network_name}
                      />
                    ),
                  }),
                )
              }}
            >
              {t('buttons.reportCountAds', { count: ads_count })}
            </BpButton>
          </div>
        )
      },
    },
    {
      key: 'actions',
      render: (_: string, { id, ads_count, is_hidden, affiliate }: NonCompliantAffiliatesModel) => {
        return (
          <Space>
            <IconButton
              size='lg'
              onClick={() => {
                dispatch(
                  setModal({
                    size: 'lg',
                    children: (
                      <PpcComplianceLastAd
                        id={id}
                        projectId={project}
                        adsCount={ads_count}
                        affiliate={affiliate}
                      />
                    ),
                  }),
                )
              }}
            >
              <IconDocument color='#A059FF' />
            </IconButton>
            <IconButton
              size='lg'
              onClick={() => {
                dispatch(
                  setModal({
                    children: <PpcComplianceEditAffiliate id={id} projectId={project} />,
                  }),
                )
              }}
            >
              <IconEdit color='#A059FF' />
            </IconButton>
            <IconButton
              size='lg'
              onClick={() =>
                hide({ id, project_id: project, is_hidden: !is_hidden }).then(() => refetch())
              }
            >
              {is_hidden ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
            </IconButton>
          </Space>
        )
      },
      width: 136,
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?:
      | SorterResult<NonCompliantAffiliatesModel>
      | SorterResult<NonCompliantAffiliatesModel>[],
  ) => {
    dispatch(
      setPpcCompliancePagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: sorter ? useSorter(sorter) : '-last_found',
    })
  }

  return (
    <div className='min-h-[400px]'>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<NonCompliantAffiliatesModel>}
        rowClassName={(e) => (e.is_hidden ? 'disabled' : '')}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </div>
  )
}
