import React, { useState } from 'react'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconCrown } from '../../../../shared/components/icons/IconCrown'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { hideModal } from '../../../../store/reducers/AppSlice'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { ColumnsType } from 'antd/lib/table'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { TriangleButton } from '../../../../shared/components/buttons/triangle/TriangleButton'
import { RadioGroup } from '../../../../shared/components/forms/radio-group/RadioGroup'
import { RadioButton } from '../../../../shared/components/forms/radio-button/RadioButton'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { useGetListQuery } from '../../../../api/tariff/Tariff.api'
import { Square } from '../../../../shared/components/utility/square/Square'
import { useSendRequestChangeTariffMutation } from '../../../../api/user/User.api'
import { selectTariffDetails } from '../../../../store/reducers/AuthSlice'
import { useTranslation } from 'react-i18next'

export const ChangePlan = ({
  step = 'show-info',
}: {
  step?: 'show-info' | 'change-plan' | 'request-sent'
}) => {
  const { t } = useTranslation()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const [state, setState] = useState<'show-info' | 'change-plan' | 'request-sent'>(step)
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [period, setPeriod] = useState<string>('annually')
  const [plan, setPlan] = useState(tariffDetails?.tariff)
  const dispatch = useAppDispatch()

  const { data } = useGetListQuery({})

  const [sendRequestChangeTariff] = useSendRequestChangeTariffMutation()

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'project_name',
      key: 'project_name',
      width: 250,
    },
    {
      title: t('table.columns.keywords'),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
      render: (count: number) => {
        return <div className={'font-semibold text-right w-full'}>{count}</div>
      },
    },
  ]

  return (
    <div className={'overflow-hidden flex flex-col'}>
      {
        {
          'show-info': (
            <div className={'flex flex-col overflow-hidden'}>
              <div
                className={
                  'flex flex-col min-h-[107px] justify-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                <h3 className={'text-[20px] font-medium pb-[4px]'}>
                  {tariffDetails?.tariff?.type === 'trial'
                    ? `Free ${tariffDetails.tariff?.duration}-day ${tariffDetails?.tariff?.name}`
                    : `${tariffDetails?.tariff?.name} $${tariffDetails?.tariff?.price} ${t(
                        'pages.tariff.' + tariffDetails?.tariff?.payment_periodicity,
                      )}`}
                </h3>
                <div
                  className={
                    'flex text-default text-[16px] leading-[24px] py-[4px] px-[12px] bg-grey-300 w-fit rounded-[8px] gap-[10px]'
                  }
                >
                  <div className={'w-[13px] pt-[9px]'}>
                    <IconCrown color={'#A059FF'} />
                  </div>
                  <p className={'flex flex-col'}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('pages.tariff.usedCountOfTotalKeywords', {
                          count: tariffDetails?.tariff?.used_keywords_count,
                          total: tariffDetails?.tariff?.max_keywords,
                        }),
                      }}
                    ></div>
                    <span className={'text-[12px] leading-[16px]'}>
                      {tariffDetails?.tariff?.type === 'trial'
                        ? t('pages.tariff.expiredDate')
                        : t('pages.tariff.nextPayment')}{' '}
                      {tariffDetails?.tariff?.subscription_expiration_date}
                    </span>
                  </p>
                  <div className={'pt-[5px]'}>
                    <BpTooltip color={'#A059FF'} title={t('pages.tariff.keywordsTooltip')} />
                  </div>
                </div>
              </div>
              <div className={'px-[24px] pb-[10px] overflow-y-auto max-h-[380px]'}>
                <BpTable
                  className={'no-padding'}
                  size={'small'}
                  dataSource={tariffDetails?.project_details}
                  rowKey={(record) => record.id}
                  hidePagination={true}
                  showHeader={!!tariffDetails?.project_details?.length}
                  columns={columns}
                  scroll={{ x: 0 }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Square depth={'100'}>
                        <div className={'p-[16px]'}>
                          {Object.keys(record.locations).map((key, index) => (
                            <div key={index} className={'flex justify-between leading-[24px]'}>
                              <span>{key}</span>
                              {record.locations[key]}
                            </div>
                          ))}
                        </div>
                      </Square>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) => (
                      <IconButton onClick={(e) => onExpand(record, e)}>
                        <TriangleButton color='#A059FF' position={expanded ? 'top' : 'down'} />
                      </IconButton>
                    ),
                    onExpand: (expanded: boolean, record: any) => {
                      expanded
                        ? setExpandedRowKeys([record.id])
                        : setExpandedRowKeys(
                            expandedRowKeys.filter((project) => project !== record.id),
                          )
                    },
                    expandedRowKeys: expandedRowKeys,
                  }}
                />
              </div>
            </div>
          ),
          'change-plan': (
            <div className={'flex flex-col overflow-y-hidden'}>
              <h3
                className={
                  'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                {t('pages.tariff.plansAndPricing')}
              </h3>
              <div className={'p-[24px] overflow-y-auto'}>
                <div className={'w-fit rounded-[6px] shadow-xs-focus'}>
                  <RadioGroup name={'period'} onChange={(e) => setPeriod(e.target.value)}>
                    <RadioButton
                      value={'monthly'}
                      placeholder={t('fields.monthly')}
                      checked={period === 'monthly'}
                    />
                    <RadioButton
                      value={'annually'}
                      placeholder={t('fields.annually')}
                      checked={period === 'annually'}
                    />
                  </RadioGroup>
                </div>
                <div className={'flex flex-col gap-[8px] pt-[16px]'}>
                  {data?.data?.map((tariff: any, index: number) => (
                    <div
                      key={index}
                      className={`p-[16px] rounded-[6px] shadow-xs-focus cursor-pointer select-none ${
                        tariff.id !== tariffDetails?.tariff?.id
                          ? 'cursor-pointer'
                          : 'cursor-default'
                      }`}
                      onClick={() => {
                        if (tariff.id !== tariffDetails?.tariff?.id) {
                          setPlan(tariff)
                        }
                      }}
                    >
                      <div className={'flex w-full justify-between items-center'}>
                        <div
                          className={
                            'flex items-center text-[20px] gap-[8px] font-medium text-focus-500'
                          }
                        >
                          <BpCheckbox
                            hidden={tariff.id === tariffDetails?.tariff?.id}
                            type='radio'
                            name={'plan'}
                            value={tariff.id}
                            checked={tariff.id === plan?.id}
                          />{' '}
                          {tariff.name}
                          <div
                            className={`text-default
                              rounded-[6px]
                              bg-grey-300
                              font-semibold
                              py-[2px]
                              px-[8px]
                              text-[10px]
                              uppercase
                              ${tariff.id === tariffDetails?.tariff?.id ? 'visible' : 'hidden'}
                            `}
                          >
                            {t('pages.tariff.current')}
                          </div>
                        </div>
                        <div className={'text-[15px] text-default font-medium'}>
                          ${period === 'monthly' ? tariff.price_monthly : tariff.price_annually}
                        </div>
                      </div>
                      <div
                        className={'flex pl-[24px] w-full justify-between text-aqua-300 items-end'}
                      >
                        <div className={'text-[15px]'}>
                          {t('pages.tariff.countKeywords', { count: tariff.max_keywords })}
                        </div>
                        <div className={'text-[13px]'}>
                          {t('pages.tariff.scannedCountDaily', {
                            count: tariff.daily_checks_count,
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                  {plan &&
                    tariffDetails.tariff &&
                    plan?.max_keywords < tariffDetails.tariff.used_keywords_count && (
                      <p className={'bg-[#FF4980] rounded-[6px] p-[12px] text-white text-[15px]'}>
                        {t('other.stopAnyActiveProjects', { name: plan?.name })}
                      </p>
                    )}
                </div>
              </div>
            </div>
          ),
          'request-sent': (
            <div className={'flex flex-col'}>
              <h3
                className={
                  'text-[20px] font-medium flex min-h-[55px] items-center px-[24px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
                }
              >
                {t('pages.tariff.requestSent')}
              </h3>
              <p className={'pt-[18px] pb-[16px] px-[24px] text-focus-500 text-[15px]'}>
                {t('pages.tariff.managerWillContact')}
              </p>
            </div>
          ),
        }[state]
      }
      <div className={'pb-[21px] pt-[24px] px-[24px] border-t-[3px] border-focus-600/[.03]'}>
        {
          {
            'show-info': (
              <div>
                <p className={'text-[15px] text-aqua-300 mb-[4px]'}>
                  {t('pages.tariff.moreKeywords')}
                </p>
                <BpButton
                  onClick={() => setState('change-plan')}
                  type='button'
                  className={'uppercase'}
                >
                  {t('pages.tariff.buttons.changePlan')}
                </BpButton>
              </div>
            ),
            'change-plan': (
              <div className={'flex gap-[14px]'}>
                <BpButton
                  onClick={() => {
                    setState('request-sent')
                    sendRequestChangeTariff({ plan: plan?.id, period })
                  }}
                  disabled={
                    plan &&
                    tariffDetails.tariff &&
                    (plan?.max_keywords < tariffDetails.tariff.used_keywords_count ||
                      plan?.id === tariffDetails.tariff.id)
                  }
                  type='button'
                  className={'uppercase'}
                >
                  {t('pages.tariff.buttons.selectPlan')}
                </BpButton>
                {tariffDetails.tariff?.type === 'custom' && (
                  <BpButton
                    onClick={() => {
                      setState('request-sent')
                      sendRequestChangeTariff({ plan: plan?.id, period })
                    }}
                    type='button'
                    className={'uppercase'}
                    theme={'outline'}
                  >
                    {t('pages.tariff.buttons.moreKeywords')}
                  </BpButton>
                )}
              </div>
            ),
            'request-sent': (
              <BpButton type='button' className={'uppercase'} onClick={() => dispatch(hideModal())}>
                {t('pages.tariff.buttons.iGotIt')}
              </BpButton>
            ),
          }[state]
        }
      </div>
    </div>
  )
}
