import { CommonMainPage } from '../../utility/CommonMainPage'
import { PpcComplianceFilters } from './PpcComplianceFilters'
import { PpcComplianceTable } from './PpcComplianceTable'

export const PpcCompliancePage = () => {
  return (
    <CommonMainPage>
      <section className='h-full grid grid-rows-[min-content_1fr]'>
        <PpcComplianceFilters />
        <PpcComplianceTable />
      </section>
    </CommonMainPage>
  )
}
