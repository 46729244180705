import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { ILiveSearchFilters } from '../../live-search/interfaces/LiveSearchFilters.interface'
import { selectLiveSearchFilters } from '../../live-search/store/LiveSearchSlice'
import { Controller, useForm } from 'react-hook-form'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import React, { useEffect, useState } from 'react'
import {
  useGetCountryQuery,
  useGetProjectsQuery,
  useGetProjectStatusQuery,
} from '../../../../api/filter/Filter.api'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { selectProjectsFilters, setProjectsFilter } from '../store/ProjectsSlice'
import { IProjectFilters } from '../interfaces/ProjectFilters.interface'
import { IconFolder } from '../../../../shared/components/icons/IconFolder'
import { Trans, useTranslation } from 'react-i18next'
import { IconPage } from '../../../../shared/components/icons/IconPage'
import { selectTariffUpdated } from '../../../../store/reducers/AuthSlice'
import { NavLink } from 'react-router-dom'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<IProjectFilters>) => {
  const { t } = useTranslation()
  const { project, country, status } = useAppSelector<IProjectFilters>(selectProjectsFilters)
  const tariffUpdated = useAppSelector(selectTariffUpdated)
  const dispatch = useAppDispatch()

  const { getValues, control, handleSubmit } = useForm<IProjectFilters>({
    mode: 'onChange',
    defaultValues: {
      project,
      country,
      status,
      ...searchParams,
    },
  })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
    refetch,
  } = useGetProjectsQuery({ all: true })

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery({ only_active_projects: true })

  const {
    data: statuses,
    isFetching: statusesFetching,
    isLoading: statusesLoading,
  } = useGetProjectStatusQuery()

  useEffect(() => {
    refetch()
  }, [tariffUpdated])

  const applyFilter = () => {
    const values = getValues()
    dispatch(setProjectsFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <section>
      <form
        className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
        onSubmit={handleSubmit(applyFilter)}
      >
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  loading={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='status'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={statuses}
                  Icon={IconPage}
                  fieldSize='lg'
                  title={t('fields.status')}
                  loading={statusesLoading || statusesFetching}
                  disabled={statusesLoading || statusesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
      </form>
    </section>
  )
}

export const ProjectsFilters = withSearchParams<
  IWithSearchParamsProps<ILiveSearchFilters>,
  ILiveSearchFilters
>(Filter, selectLiveSearchFilters)
